<template>
  <div class="qa-container">
    <van-nav-bar title="问答" />
    <van-empty description="正在努力开发中..." />
  </div>
</template>

<script>
export default {
  name: 'QaIndex',
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.qa-container {
}
</style>
